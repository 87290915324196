<template>
  <section class="faq">
      
      
      
      
     
    <div class="page-header">
      <h3 class="page-title">
        Объект {{ rldata['objcode'] }}
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">Заявки</li>
          <li class="breadcrumb-item active" aria-current="page"><router-link class="" v-bind:to="'/'"  >Назад</router-link></li>  
        </ol>
      </nav>
    </div>
     
      
  
   
      
       
       
       
       
       
       
       
    
     
            
            
         
           
           
          <div class="col-12 grid-margin">
            <div class="card">
              <div class="faq-block card-body">
                  
                
                  
<div   v-if="rldata['is_loaded_tickets']"> 
                           
                      
                  <p v-if="rldata.flagserror_common.is_error_in_sending_data" class="text-danger">{{ this.$root.error_text.text_error_in_sending_data }}</p>
                  <p v-if="rldata.flagserror_common.is_error_execution_api" class="text-danger" >{{ this.$root.error_text.text_error_execution_api }}</p>
                  <p v-if="rldata.flagserror_action.is_error_faild_limit" class="text-danger" >{{ rldata['flagserror_action']['text_error_faild_limit'] }}</p>
                    
                    
                      
                      
                      
                           
                        <div class="card" >
                                    <div   class="card-body pb-0">
                                      <h4 class="card-title mb-4"  id="mmm" name='mmm'>Список заявок</h4>
                                    </div>
                              <div class="card-body p-0">
            
                                  

                                <div class="table-responsive">
                                  <table class="table custom-table text-dark">
                                    <thead>
                                      <tr>
                                          <th>Качество</th>
                                          <th></th>
                                        <th>Num</th>                                          
                                        <th>FIN</th> 
                                        <th>UP</th> 
                                     
                                        
                                        <!-- <th>sender_ip</th>   --> 
                                        
                                        <th>Телефон</th>
                                        <th>Звонок?</th>
                                        <th>Имя</th>   
                                        <th>Проблема</th> 
                                        
                                        <th>Заяв/Рез/Общ</th> 
                                         <th>Статус/LOCCODE</th>
                                        
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(item, index) in rldata['a_tickets']" :key="item.ticknum"   v-bind:class='(rldata.mark_ticknum==item.ticknum?"text-danger":"text-gray")' v-bind:ref="item.ticknum"  v-bind:id="'id_'+item.ticknum"  >
                                           
                                          
                                             <td  >
                                              
                                                 
                                                <div v-if="item.status!=='success'"  > 
                                                   <star-rating v-bind:increment="1" v-model="item.ticket_raiting" v-bind:max-rating="5" v-bind:star-size="14" v-bind:show-rating="false"></star-rating> 
                                                 </div>
                                                  <div v-else> 
                                                      <star-rating v-bind:read-only='true' v-bind:increment="1" v-model="item.ticket_raiting" v-bind:max-rating="5" v-bind:star-size="14" v-bind:show-rating="false"></star-rating> 
                                                  </div>
                                                 <p>  
                                                                                             
                                                   <div  v-on:click="pin_ticket" v-bind:ticknum="item.ticknum" v-bind:is_pin="item.is_pin" >
                                                 {{ item.is_pin==='1' ? "+": ""  }}  {{ item.is_correct_location==='1' ? item.qrinfo : (item.client_location===null ? item.qrinfo : item.client_location) }}  
                                                     </div>
                                              
                                               
                                             </td>
                                          
                                          <td>
                                                  <div v-if="item.status!=='success'">
                                                            <b-button :id="'fin_'+item.ticknum"  class="btn  btn-sm mr-3 mt-2 mb-2"     v-on:click="fin_close_ticket" v-bind:ticknum="item.ticknum"  v-bind:class='"btn-danger"' :disabled="rldata['flags_logic']['is_act_disabled']"   >
                                                                 F/{{item.cnt_cleaning_close}} 
                                                          </b-button>
                                                      </div>
                                              <div v-else>
                                                OK/{{item.cnt_cleaning_close}}
                                              </div>
                                             </td>
                                          <td>
                                                  <p>{{item.ticknum}}</p>
                                             </td> 
                                             
                                               <td>
                                                  <div v-if='item.img_link_close!==null && item.img_link_close!==""'>
                                                    <img id="photo3" v-on:click="showAlertClose" v-bind:ticknum='item.ticknum' v-bind:status='item.status' v-bind:client_location='item.client_location'  v-bind:client_problem='item.client_problem' v-bind:client_name='item.client_name' v-bind:client_tel='item.client_tel' v-bind:client_is_requare_call='item.client_is_requare_call' style="width: 50px;" v-bind:src="$root['rdata']['img_cloud_url']+item.img_link_close"> 
                                                  </div>
                                             </td>
                                             <td>
                                                     <div v-if='item.img_link!==null && item.img_link!==""'>
                                                        <img id="photo2" v-on:click="showAlert" v-bind:ticknum='item.ticknum' v-bind:status='item.status' v-bind:client_location='item.client_location' v-bind:client_problem='item.client_problem' v-bind:client_name='item.client_name' v-bind:client_tel='item.client_tel' v-bind:client_is_requare_call='item.client_is_requare_call' style="width: 50px;" v-bind:src="$root['rdata']['img_cloud_url']+item.img_link">
                                                     </div>
                                             </td>
                                            
                                             
                                             
                                             
                                           <!-- <td>
                                                  <p>{{item.datetime_created}}</p>
                                             </td> -->
                                            
                                                
                                                <td>
                                                  <p> {{ item.client_tel }} </p>
                                             </td>
                                                <td>
                                                  <p> {{ item.client_is_requare_call==='1'?"Звонок ДА":""}} </p>
                                             </td>
                                               <td>
                                                  <p> {{ item.client_name }} </p>
                                             </td>
                                              <td>
                                                  <p v-if="!rldata.is_show_edit_client_problem_add"> {{ item.client_problem }}/{{ item.client_problem_add }}<i data-v-6713abfe="" class="mdi mdi-pencil menu-icon" style="color: black;"  v-on:click="show_edit_client_problem_add"></i>  </p> 
                                                  <div v-else class="d-flex flex-row align-items-center"><b-input style="min-width: 120px;" :id="'ticknum_'+item.ticknum" v-model="item.client_problem_add"   placeholder="Доп. Инфо."  ></b-input> <i data-v-6713abfe="" class="mdi mdi-checkbox-marked-circle menu-icon" style="color: black;"   v-on:click="update_client_problem_add" v-bind:ticknum="item.ticknum" ></i></div> 
                                             </td>
                                             
                                              
                                               
                                             <td> 
                                                  <p> {{ item.client_raiting===null || item.client_raiting==="0" ? "-" : item.client_raiting }} &nbsp/&nbsp {{ (item.client_raiting_after_ticket===null ? "-" : item.client_raiting_after_ticket ) }} &nbsp/&nbsp {{ (item.client_raiting_after_overall===null ? "-" : item.client_raiting_after_overall ) }} </p>
                                             </td>
                                        
                                             
                                           <td>
                                                  <p>{{item.status}}/{{item.loccode}}</p>
                                             </td> 
                                             
                                        
                                             
                                              
                                             
                                            
                                        
                                      </tr>
                                     
                                      
                                    </tbody>
                                  </table>
                                </div>




                                
                              </div>
                        </div>

                      
                  
                      
                      
                      
</div>
              </div>
            </div>
          </div>
           
              
 
            
            
            
            
       
        
            
            
            
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
                           
                                     <!--     v-on:click="show_qr"
                                      <b-button :id="'t_'+item.ticknum"  class="btn  btn-sm mr-3 mt-2 mb-2"     v-bind:qrguid="item.ticknum"  v-bind:class='"btn-danger"'   >
                                                       QR 
                                                </b-button>
                                     
                                     
                                     
                                          <td> 
                                            <div    class="d-flex flex-row">
                                                <b-button :disabled="rldata['flags_logic']['is_disabled_up_down_buttons']"  :id="'qrord_up_'+item.idqr"  class="btn    btn-sm  mt-2 mb-2 ml-2 mr-2"     v-on:click="qrord_up" v-bind:loccode="item.loccode" v-bind:qrguid="item.qrguid"  v-bind:class='"btn-light"'    >&uarr;</b-button>
                                       
                                                <b-button :disabled="rldata['flags_logic']['is_disabled_up_down_buttons']" :id="'qrord_down_'+item.idqr"  class="btn    btn-sm  mr-2 mt-2 mb-2 "     v-on:click="qrord_down" v-bind:loccode="item.loccode" v-bind:qrguid="item.qrguid"  v-bind:class='"btn-light"'    >&darr;</b-button>
                                           </div>
                                            
                                        </td>
                                        <td>
                                              {{item.objcode}}
                                        </td>
                                        <td> <router-link    class="   " v-bind:to="item.qrlink">{{item.loccode}}  </router-link>  </td>
                                           
                                  
                                     
                                        <td>  
                                            <b-button v-if='!!parseInt(item.is_marked)' :id="'remove_mark_'+item.idqr"  class="btn  btn-sm mr-3 mt-2 mb-2"   v-bind:qrguid="item.qrguid" v-bind:is_marked="item.is_marked" v-on:click="change_mark" v-bind:loccode="item.loccode"  v-bind:class='"btn-gray"'   >
                                                !
                                            </b-button>
                                            <b-button v-else                            :id="'set_mark_'+item.idqr"     class="btn  btn-sm mr-3 mt-2 mb-2"   v-bind:qrguid="item.qrguid" v-bind:is_marked="item.is_marked" v-on:click="change_mark" v-bind:loccode="item.loccode"  v-bind:class='"btn-light"'   >
                                                + 
                                            </b-button>
                                        
                                        </td>
                                        
                                        <td>  <b-input :id="'qrinfo_'+item.idqr" v-model="item.qrinfo"   placeholder="Локация"  >{{item.qrinfo}} </b-input> </td>
                                       
                                      
                                        
                                    
                                        <td> 
                                            <div class="d-flex flex-row">
                                                <b-input :id="'qrcomment_'+item.idqr" v-model="item.qrcomment"   placeholder="Комментарий"  > </b-input>
                                                 <b-button  :id="'update_qrrow_'+item.idqr"  class="btn    btn-sm  mr-3 mt-2 mb-2 ml-2"     v-on:click="update_qrrow" v-bind:qrguid="item.qrguid"  v-bind:class='"btn-gray"'    >
                                                   Сохранить
                                                </b-button>
                                            </div>
                                        </td>
                                        
                                    -->
      
      
      
      
      
      
      
      
      
      
      
        
         
  
  
  
  
  </section>
</template>

<script>
import $ from 'jquery'
import axios from 'axios';
import axiosRetry from 'axios-retry';       
import Swal from 'sweetalert2' 
import StarRating from 'vue-star-rating';  
window.swal = Swal; //registr asscess swal.close() in onclick

var data = {
    
    pkey :"",
    objcode :"",
    a_tickets: [],
  
    cloud_url : "",
    cloud_domain : "",
    
    mark_ticknum : "0",
    
    is_loaded_tickets: false,
       
   is_show_edit_client_problem_add: false,
    
    flags_logic: {
        is_act_disabled : false,
        //is_disabled_up_down_buttons : false,
    },    
    flagserror_common: {
            is_error_in_sending_data: false,
            is_error_execution_api: false,
    },    
    flagserror_action: {
            //is_error_faild_limit: false,
            //text_error_faild_limit: " Пул номеров в 260 штук в диапазоне A0-Z9 закончались, далее ручной ввод",
    },
    
    
}      
        
export default {
  name: 'addqr',
   
components: {
  StarRating
},
   
    data () { //тюненгуемые переменные и функции
        return {
            rldata: data,  
        };
    },
  
  
  mounted () {
    
    
    // DINAMIC CSS INSERT
    //document.styleSheets[0].insertRule("@media screen and (min-width: 1500px) { .ggghg  { color:green!important;  }    }","");
    //document.styleSheets[0].insertRule("@media screen and (min-width: 1500px) { .card .card-title {   color:green!important;  } }","");
    document.styleSheets[0].insertRule("@media screen and (min-width: 1899px) { .content-wrapper  {  max-width: 1840px!important;  }    }","");
    document.styleSheets[0].insertRule("@media screen and (min-width: 1599px) { .content-wrapper  {  max-width: 1540px!important;  }    }","");
    document.styleSheets[0].insertRule("@media screen and (min-width: 1399px) { .content-wrapper  {  max-width: 1340px!important;  }    }","");
   
   
   // this.$root.check_version(this.rldata, true);
   
       
         //получить ключ из ззылки - авторизация light
    var url = new URL(window.location.href);  
    
    if (url.searchParams.get("pkey")==="1122")
    {
    this.rldata['pkey']=url.searchParams.get("pkey");     
    localStorage.setItem(this.rldata['pkey'],true);
    localStorage.setItem('pkey', this.rldata['pkey']);
    }
    else
    {
        this.$router.push('/');
    }
    
    if (url.searchParams.get("objcode") !== null)
    {
    this.rldata['objcode']=url.searchParams.get("objcode").toUpperCase();    
    localStorage.setItem(this.rldata['objcode'],true);
    localStorage.setItem('objcode', this.rldata['objcode']);
    }
    else
    {
        this.$router.push('/');
    }
    
    
    
    let url_mark_ticknum=url.searchParams.get("mark_ticknum");  
    if (url_mark_ticknum!==null) {
         this.rldata['mark_ticknum']=url_mark_ticknum;        
    }
    else
    {
        this.rldata['mark_ticknum']="0";
    }
    
    
          
    this.rldata['cloud_url']=this.$root.rdata['cloud_url'];
    this.rldata['cloud_domain']=this.$root.rdata['cloud_domain'];     
    
                
                
    this.$root.get_tickets_by_objcode_for_10days(this.rldata); 
   
         
   this.rldata['is_show_edit_client_problem_add']=false; 
   this.rldata['flags_logic']['is_act_disabled']=false;
   this.rldata['flagserror_common']['is_error_in_sending_data']=false;
   this.rldata['flagserror_common']['is_error_execution_api']=false;
  
  
  
          
              
                   
                   
                  
                   
                   
           
    
 
  
  },  
  
  
  
   updated() {
    // Fired every second, should always be true
    
    if (this.rldata['mark_ticknum']!=="0")
    {
         console.log(this.$refs);//[url_mark_ticknum].scrollIntoView();//{behavior: "smooth", block: "start", inline: "start"}
         console.log(this.$refs[this.rldata['mark_ticknum']]);
         let el=this.$refs[this.rldata['mark_ticknum']];
         if (el)
         {
            document.querySelector('#id_'+this.rldata['mark_ticknum']).scrollIntoView({
                block: 'start',
                behavior: 'smooth',
              });
              window.scrollBy(0, -60);
            
          /*  var headerOffset = 25;
            var elementPosition = el.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;            
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
           });*/
         }
     }

  },
  /*  watch: {
    // whenever question changes, this function will run
      result(newResult) {
        if (newResult) {
            this.$nextTick().then(() => document.querySelector('#result').scrollIntoView());
        }
    },
    
     
  },*/
 
  
methods: {
     
      show_edit_client_problem_add : function (event)   //(event) 
       {
           this.rldata['is_show_edit_client_problem_add']=!this.rldata['is_show_edit_client_problem_add'];
       },
       
        update_client_problem_add : function (event)   //(event) 
       {
           
           
           
             let ticknum= event.target.getAttribute('ticknum'); 
         
        let r_tickets="";          
        for (const [key, value] of Object.entries(this.rldata['a_tickets'])) { 
          if (value['ticknum']===ticknum)
          {  // console.log("BINGO");
              r_tickets=value;
              break;
              
          }
        } 
        
      
           
           
             
        this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        this.rldata['flags_logic']['is_act_disabled']=true;
       
      
        
         var url=this.$root.rdata['server_auth'];
                 
                  
             var params = {
                  method:  'common__tlist_update_client_problem_add', 
                   data:  {
                     ticknum : ticknum,   
                     objcode : r_tickets['objcode'],
                     client_problem_add : r_tickets['client_problem_add'],
                  } 
              }; 
              
           
   console.log(params);  
   
   
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                               
                            //  console.log(response['data']['data']['qrinfo']);
                              
                    //НЕ ПЕРЕЗАКАЧИВАЕМ ДАННЫЕ!
                              // this.rldata['a_tickets']=response['data']['data']['a_tickets'];
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                                this.rldata['is_show_edit_client_problem_add']=!this.rldata['is_show_edit_client_problem_add'];
                               
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                       
                           
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act_disabled']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true;
                    //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     this.rldata['flags_logic']['is_act_disabled']=false;
                    
            }).then(function () {  });
       
         
        
           
           
           
       },
     
       
       pin_ticket : function (event)   //(event) 
       {
           
                let ticknum= event.target.getAttribute('ticknum'); 
         
        let r_tickets="";          
        for (const [key, value] of Object.entries(this.rldata['a_tickets'])) { 
          if (value['ticknum']===ticknum)
          {  // console.log("BINGO");
              r_tickets=value;
              break;
              
          }
        } 
        
        if (r_tickets['is_pin']==="1")
        {
             console.log("PINED");
        }else
        {
             console.log("NOT PINED");
        }
           
           
             
        this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        this.rldata['flags_logic']['is_act_disabled']=true;
       
      
        
         var url=this.$root.rdata['server_auth'];
                 
                  
             var params = {
                  method:  'common__tlist_pin_ticket', 
                   data:  {
                     ticknum : ticknum,   
                     objcode : r_tickets['objcode'],
                     is_pin : r_tickets['is_pin'],
                  } 
              }; 
              
           
  
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                               
                            //  console.log(response['data']['data']['qrinfo']);
                               
                               this.rldata['a_tickets']=response['data']['data']['a_tickets'];
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                               
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                       
                           
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act_disabled']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true;
                    //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     this.rldata['flags_logic']['is_act_disabled']=false;
                    
            }).then(function () {  });
       
         
        
          
           
           
           
           
       },
   
      fin_close_ticket : function (event)   //(event) 
    {  //        console.log(event['target']['id']);
        
         let ticknum= event.target.getAttribute('ticknum'); 
         
        let r_tickets="";          
        for (const [key, value] of Object.entries(this.rldata['a_tickets'])) { 
          if (value['ticknum']===ticknum)
          {   console.log("BINGO");
              r_tickets=value;
              break;
              
          }
        } 
        
        //console.log(input_val);
       
       
         
        this.rldata['flagserror_common']['is_error_in_sending_data']=false;
        this.rldata['flagserror_common']['is_error_execution_api']=false;
        this.rldata['flags_logic']['is_act_disabled']=true;
       
      
        
         var url=this.$root.rdata['server_auth'];
                 
                  
             var params = {
                  method:  'common__tlist_fin_close_ticket', 
                   data:  {
                     ticknum : ticknum,   
                     objcode : r_tickets['objcode'],
                     ticket_raiting : r_tickets['ticket_raiting'],
                  } 
              }; 
              
           
  
            axios.post(url, params )    
              .then(response => 
              {   
                   console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                              console.log("READY");
                               
                            //  console.log(response['data']['data']['qrinfo']);
                               
                               this.rldata['a_tickets']=response['data']['data']['a_tickets'];
                              
                                this.rldata['flagserror_common']['is_error_execution_api']=false;
                               
                                //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                               
                           break;
                          
                       
                           
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             this.rldata['flagserror_common']['is_error_execution_api']=true;
                             this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                       
                  //разрешаем кликать на кнопку для дейтсвия 
                   this.rldata['flags_logic']['is_act_disabled']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    this.rldata['flagserror_common']['is_error_in_sending_data']=true;
                    //this.rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                    //разрешаем кликать на кнопку для дейтсвия try_auth
                     this.rldata['flags_logic']['is_act_disabled']=false;
                    
            }).then(function () {  });
       
         
        
        
        
        
        
         
    },
    
    
    
    
    
    
    
    
    
       showAlert (event) {
          
        let ticknum= event.target.getAttribute('ticknum'); 

        let status= event.target.getAttribute('status'); 
        let client_problem= event.target.getAttribute('client_problem'); 
        let client_name= event.target.getAttribute('client_name'); 
        let client_tel= event.target.getAttribute('client_tel'); 
        let client_location= event.target.getAttribute('client_location');
        let client_is_requare_call= event.target.getAttribute('client_is_requare_call'); 
         
        let r_tickets="";          
        for (const [key, value] of Object.entries(this.rldata['a_tickets'])) { 
          if (value['ticknum']===ticknum)
          {   console.log("BINGO");
              r_tickets=value;
              break;
              
          }
        }      
        
      Swal.fire({
       
        html:
          '<div onclick="swal.closeModal(); return false;" class="div_up" >Проблема клиента : ' + client_problem +'</br></br>' +
          ''+client_location + ' (' + client_name + ', +7' + client_tel + ') '+'</br></br>' +
          '<img onclick="swal.closeModal(); return false;" class="img_up" style="max-width:800px !important; max-height:600px !important; width:100%;" src="'+this.$root['rdata']['img_cloud_url']+r_tickets['img_link']+'" />' +
          '</div>',
        width:850,
        animation : false, 
       
})
      
   
    },
    
    
       showAlertClose (event) {
          
        let ticknum= event.target.getAttribute('ticknum'); 

        let status= event.target.getAttribute('status'); 
        let client_problem= event.target.getAttribute('client_problem'); 
        let client_name= event.target.getAttribute('client_name'); 
        let client_tel= event.target.getAttribute('client_tel');
         let client_location= event.target.getAttribute('client_location');
        let client_is_requare_call= event.target.getAttribute('client_is_requare_call'); 

         
        let r_tickets="";          
        for (const [key, value] of Object.entries(this.rldata['a_tickets'])) { 
          if (value['ticknum']===ticknum)
          {   console.log("BINGO");
              r_tickets=value;
              break;
              
          }
        }      
        
          
    
        
      Swal.fire({ 
            html:
          '<div onclick="swal.closeModal(); return false;" class="div_up" >Проблема клиента : ' + client_problem +'</br></br>' +
          ''+client_location + ' (' + client_name + ', +7' + client_tel + ') '+'</br></br>' +
          '<img onclick="swal.closeModal(); return false;" class="img_up" style="max-width:800px !important; max-height:600px !important; width:100%;" src="'+this.$root['rdata']['img_cloud_url']+r_tickets['img_link_close']+'" />' +
          '</div>',
        width:850,
        animation : false, 
        
    
})
      
   
    },
    
     
     
     
     

     
     
     
     
    
    
   
},
  
  
 
   computed: { 
       
       
   
       
     check_is_act_disabled () { 
    //проверяет доступна ли кнопка действия (галка + должно быть завершено предидущее действие кнопки)
     if (this.rldata['flags_logic']['is_act_disabled'] || this.rldata['qrinfo'].trim().length<2 || this.rldata['qrinfo'].trim().length>this.rldata['qrinfo_limit'] ){//!39 лимит для QR-10 для M сжатия - 15% потери
        return true;//true - заблокирована
     }
     return false;
    },
    
    
  
},
  
}
</script>


<style scoped>
    .accordion .card .card-header a{
        padding-right: 1.5rem;
    }
    
    .anim-effect {
        transition: .5s;
    }
  
    /*
    @media screen and (min-width: 1500px) { .card .card-title {   color:green!important;  } }
 */
 
 
    
    
</style>